.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

header {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
}

.content {
  background-color: #ddd;
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.summary {
  background-color: #eee;
  padding: 1rem;
  position: fixed;
  bottom: 60px;
  width: 100%;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.payment {
  background-color: #ffcc00;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.subtotal {
  background-color: #f2f2f2;
  padding: 1rem;
  margin-top: 1rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
}

.subtotal.no-padding {
  padding: 0;
}

.subtotal.no-padding .table {
  margin: 0;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }

  .content {
    flex: 3;
    overflow-y: scroll;
    height: calc(100vh - 100px);
    padding-right: 1rem;
  }

  .summary {
    background-color: #eee;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .payment {
    flex: 1;
    position: static;
    width: auto;
    margin-left: 1rem;
    box-shadow: none;
  }

  .subtotal {
    flex: 1;
    margin-top: 0;
    margin-left: 1rem;
  }
}
