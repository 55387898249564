.free-item-by-value-container {
  display: grid;
  row-gap: 16px;
  column-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.free-item-by-value-item {
  border-radius: 12px;
  background: var(--gray-10);
  padding: 8px 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
}

.free-item-by-value-item-left-side {
  position: absolute;
  left: 6px;
  bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.free-item-by-value-item-left-side>p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

.free-item-by-value-item-right-side {
  position: absolute;
  right: 6px;
  bottom: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.free-item-by-value-item-right-side-qty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 24px;
  background-color: #fff;
  border-radius: 6px;
}

.free-item-by-value-item-right-side-qty>span {
  font-size: 14px;
}