.bottomModal .modal-dialog {
  margin: auto;
  transform: translateY(calc(100vh - 90%));
  max-height: 90%;
  transition: transform 0.3s;
  max-width: 80%;
}

.bottomModal .modal-dialog::before {
  content: "";
  display: block;
  height: 10vh;
}