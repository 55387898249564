.facebook-login {
  border: none;
  border-radius: 4px;
  background: #4267b2;
  flex: 1;
  color: #fff;
  display: flex;
  align-items: center;
  height: 38px;
}

.google-login {
  border: none;
  border-radius: 4px;
  background: #1778F2;
  flex: 1;
  color: #fff;
  display: flex;
  align-items: center;
  height: 38px;
}

.toggle-password {
  right: 10px;
  font-size: 20px;
}